.clients-carousel-root {
  height: max-content;
  margin: 48px 0;
  padding: 24px 0 12px;
  background-color: #F7F7F7;
}

.clients-carousel-root h3 {
  margin-bottom: 32px;
  font-size: 26px;
  font-weight: 700;
  color: #04525F;
  text-align: center;
}

.clients-carousel-root .inner {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 5rem;
}

.clients-carousel-root .wrapper {
  position: absolute;
  display: flex;
}

.clients-carousel-root section {
  display: flex;
  animation: swipe var(--speed) linear infinite backwards;
}

.clients-carousel-root .image {
  max-width: unset;
  margin: 0 20px;
  height: 70px;
  padding: 0 15px;
  object-fit: cover;
}

.image:last-of-type {
  padding-left: 0;
}

@keyframes swipe {
  0% {
    transform: translate(0);
  }

  100% {
    transform: translate(-100%);
  }
}

@media only screen and (max-width: 1024px) {
  .clients-carousel-root h3 {
    font-size: 20px !important;
    margin-bottom: 16px !important;
  }

  .inner {
    height: 3rem;
  }

  .image {
    margin: 0 10px;
    height: 50px;
    padding: 0 10px;
  }
}
