.blog-root {
  background-color: rgb(230, 230, 230);
  padding: 20px 0;
}

.publish-date {
  position: absolute;
  right: 20px;
  top: 10px;
  color: white;
  background-color: black;
  padding: 4px 8px;
  font-size: 14px;
  border-radius: 8px;
}

.blog-root .title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
  color: white;
}

.blog-container {
  margin: auto;
  background-color: white;
  max-width: 1000px;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
  border-radius: 10px;
}

.blog-header {
  position: relative;
}

.blog-title h1 {
  margin-bottom: 20px;
  color: #333;
  margin-left: 20px;
}

.blog-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 10px;
}

.blog-root .blog-content {
  margin-top: 20px;
  color: #444;
  line-height: 1.6em;
}

.blog-root .blog-meta-description {
  margin-top: 12px;
  color: grey;
}


