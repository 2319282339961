.admin-dashboard-blogs-root span {
  border-bottom: 5px solid #463f39;
  display: block;
  margin-top: 7px;
  width: 35%;
}

.admin-dashboard-blogs-root h2 {
  width: max-content;
  font-weight: 600;
}

.blog-input-container {
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  justify-content: center;
  align-items: center;
  padding: 32px !important;
}

.add-blog-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-blog-btn {
  margin: 16px 0px;
  padding: 12px 24px !important;
  font-size: 20px !important;
}

.admin-dashboard-blogs-root .blogs-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.admin-dashboard-blogs-root .blog-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.list-group-item {
  cursor: pointer;
}

.ql-editor {
  min-height: 200px;
}

.buttons {
  display: flex;
  justify-content: center;
  column-gap: 20px;
  margin-top: 20px;
}

.list-buttons {
  display: flex;
  justify-content: center;
  column-gap: 20px;
}

.text-field {
  margin-bottom: 20px;
}

@media only screen and (max-width: 1024px) {
  .admin-dashboard-blogs-root h2 {
    font-size: 20px;
  }

  .admin-dashboard-blogs-root span {
    width: 50%;
  }

  .add-blog-btn {
    margin: 16px 0px;
    padding: 8px 16px !important;
    font-size: 18px !important;
  }

  .admin-dashboard-blogs-root .blogs-list {
    gap: 10px;
  }

  .list-buttons {
    flex-direction: column;
    row-gap: 10px;
  }

  .ql-editor {
    min-height: 150px;
  }
}