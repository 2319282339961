.report-detail-form-form-container {
  display: flex;
  justify-content: center;
  margin: 16px auto;
  flex-direction: column;
  width: max-content;
  border-radius: 4px;
  padding: 36px 72px;
}

.report-detail-form-txt {
  font-weight: 600 !important;
  margin-bottom: 28px !important;
}

.report-detail-form-text-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.report-detail-form-label {
  font-size: 15px;
  color: black;
  text-align: right;
  width: 100%;
}

.report-detail-form-input {
  height: 35px;
  font-size: 15px;
}

.report-detail-form-your-question {
  height: 100px;
  width: 434px;
}

.report-detail-form-company-input {
  height: 35px;
  font-size: 15px;
}

.report-detail-form-select-country {
  height: 35px;
  font-size: 15px;
}

.report-detail-form-submit-btn {
  display: flex;
  justify-content: center;
}

.report-detail-form-submit-btn {
  text-align: center;
  margin-top: 6px;
  font-size: 15px;
  padding: 3px;
  border-radius: 5px;
}

.report-detail-form-form {
  margin-top: 12px;
}

.report-detail-form-form input,
.report-detail-form-form select,
.report-detail-form-textArea {
  width: 400px;
}

.report-detail-form-submit-btn {
  margin-top: 6px;
  font-size: 15px;
  padding: 3px;
  border-radius: 5px;
}

.report-detail-form-td {
  padding: 8px;
  padding-right: 4px;
}

@media only screen and (max-width: 1024px) {
  .report-detail-form-form-container {
    padding: 20px 15px;
    width: 100%;
  }

  .report-detail-form-text-btn {
    flex-direction: column;
    align-items: flex-start;
  }

  .report-detail-form-form input,
  .report-detail-form-form select,
  .report-detail-form-textArea {
    width: 100%;
  }

  .report-detail-form-txt {
    font-size: 16px;
    text-align: center;
    font-weight: 700 !important;
    width: 100%;
  }

  .report-detail-form-label {
    font-size: 14px;
  }

  .report-detail-form-input,
  .report-detail-form-select-country,
  .report-detail-form-textArea {
    font-size: 14px;
  }

  .report-detail-form-td {
    padding: 7px;
  }
}
