.thanks-root {
  display: flex;
  justify-content: center;
  align-items: center;
  row-gap: 16px;
  margin: 32px auto;
  flex-direction: column;
  width: max-content;
  padding: 36px 72px;
}

.thanks-root .checkbox-image {
  width: 140px;
}

.thanks-root .description-text {
  font-size: 18px;
  width: 400px;
  text-align: center;
}

.thanks-root .socials {
  margin-top: 16px;
  display: flex;
  column-gap: 24px;
  justify-content: center;
  width: 100%;
}

.thanks-root .social-link {
  border: 1px solid #055160;
  padding: 8px;
  border-radius: 50%;
}

.thanks-root .thank-you-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #055160;
  font-size: 54px;
  font-weight: 600;
}

@media only screen and (max-width: 1024px) {
  .thanks-root {
    padding: 0;
  }

  .thanks-root .checkbox-image {
    width: 70px;
  }

  .thanks-root .description-text {
    font-size: 14px;
    width: 240px;
  }

  .thanks-root .thank-you-text {
    font-size: 32px;
  }
}
