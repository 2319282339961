.card-img {
  object-fit: cover;
  position: relative;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.card-desc {
  max-height: 100px;
  overflow: hidden;
}

.card-meta {
  color: grey;
}

.card-publishdate {
  position: absolute;
  right: 20px;
  top: 10px;
  color: white;
  background-color: black;
  padding: 4px 8px;
  font-size: 14px;
  border-radius: 8px;
}

.card-button {
  background-color: grey;
  color: white;
  font-size: 18px;
}

.search-input {
  font-size: 16px;
  padding: 5px;
  width: 300px;
  border: 2px solid black;
}

@media only screen and (max-width: 1024px) {
  .search-input {
    width: 120px;
    font-size: 12px;
  }
}
