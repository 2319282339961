.admin-login-root {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background: #e0e0e0;
}

.admin-login-header {
  padding: 12px 48px !important;
  min-height: 80px;
  max-height: 80px;
}

.login-container {
  display: flex;
  justify-content: center;
  height: calc(100vh - 80px);
  margin-bottom: 64px;
}

.admin-login-form {
  width: 350px;
  padding: 24px;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  background-color: #f8f9fa;
}

.admin-login-form .login-title {
  margin-bottom: 24px;
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.admin-login-form button {
  margin-top: 20px;
}
