.terms-and-condition-container {
  margin-top: 20px !important;
  margin-bottom: 40px !important;
}

.p-terms-and-condition {
  margin: 10px 0;
  font-size: 16px;
}

.subheading-d-terms-and-condition-root {
  margin: 10px 0;
}

.terms-and-condition-heading {
  font-size: 20px;
  margin: 20px 0 10px;
}

.terms-and-condition-description {
  font-size: 16px;
}

.p-terms-and-condition {
  font-size: 16px;
}

.terms-and-condition-root {
  margin: 10px 0;
}

@media only screen and (max-width: 1024px) {
  .p-terms-and-condition {
    font-size: 14px;
  }

  .terms-and-condition-heading {
    font-size: 18px;
  }

  .terms-and-condition-description {
    font-size: 14px;
  }

  .p-terms-and-condition {
    font-size: 14px;
  }
}
