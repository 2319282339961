.faqs-root {
  margin: 20px 0 40px;
}

.q-and-a-component-root {
  margin: 16px 0;
}

.q-and-a-component-heading {
  font-size: 24px;
  font-weight: 600;
  text-decoration: underline;
  padding-left: 15px;
}

.q-and-a-component-description {
  font-size: 18px;
  padding-left: 26px;
}

.question-answer-root {
  margin-top: 8px;
}

.q-and-a-component-root .faq-question {
  font-size: 18px;
  font-weight: 500;
}

.q-and-a-component-root .question-answer-root button {
  text-decoration: none;
  color: #000;
  padding: 0;
  text-align: left;
}

.q-and-a-component-root .faq-answer-a {
  font-size: 16px;
}

@media only screen and (max-width: 1024px) {
  .faqs-root {
    margin: 20px 0 40px;
  }

  .q-and-a-component-description {
    font-size: 16px;
    letter-spacing: 0.5px;
    font-weight: 300px;
  }

  .q-and-a-component-heading {
    font-size: 20px;
  }

  .faq-answer-a {
    font-size: 14px;
    letter-spacing: 0.5px;
    font-weight: 300px;
  }

  .faq-question {
    font-size: 16px;
  }
}
