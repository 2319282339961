.admin-dashboard-reports-root h2 {
  width: max-content;
  font-weight: 600;
}

.admin-dashboard-reports-root .underline {
  border-bottom: 5px solid #463f39;
  display: block;
  margin-top: 7px;
  width: 35%;
}

.add-reports-btn {
  margin: 16px 0px;
  padding: 12px 24px !important;
  font-size: 20px !important;
}

.upload-dates-container {
  display: flex;
  flex-wrap: wrap;
  margin: 16px 0;
  row-gap: 16px;
}

.cross-icon {
  color: #d22b2b;
  cursor: pointer;
  position: relative;
  right: 12px;
  top: -14px;
  font-size: 20px;
}

.file-action-title {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 10px;
}

.file-action-buttons {
  display: flex;
  justify-content: center;
  column-gap: 10px;
}

.publisher-input-container {
  display: flex;
  column-gap: 8px;
  align-items: center;
}

.publisher-input-container label {
  margin-bottom: 0px !important;
}

.publisher-input {
  height: 36px;
  border: 1px solid black;
  border-radius: 8px;
  font-size: 16px;
  padding: 2px 8px;
}

.dropzone {
  border: 2px dashed #000;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 0 auto;
  padding: 20px;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
}

.report-input-container {
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  justify-content: center;
  align-items: center;
  padding: 32px !important;
}

.admin-dashboard-reports-root .report-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 40px;
}

.report-content .fs-6 {
  margin-right: auto;
}

.admin-dashboard-reports-root .pagination {
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}

.admin-report-header-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.segmentation-buttons {
  display: flex;
  column-gap: 8px;
}

@media only screen and (max-width: 1024px) {
  .admin-report-header-buttons {
    flex-direction: column;
    margin: 16px 0px;
    row-gap: 16px;
  }

  .add-reports-btn {
    margin: 0px;
    padding: 10px 18px !important;
    font-size: 18px !important;
  }

  .report-content .fs-6 {
    margin-right: 0;
    font-size: 14px;
  }

  .admin-dashboard-reports-root h2 {
    font-size: 24px;
  }

  .add-reports-btn,
  .upload-date-btn {
    font-size: 14px;
    padding: 8px 12px !important;
  }

  .upload-dates-container {
    flex-direction: column;
    align-items: center;
  }

  .publisher-input-container {
    flex-direction: column;
    row-gap: 8px;
  }

  .publisher-input {
    width: 100%;
    height: 30px;
  }

  .dropzone {
    font-size: 14px;
    padding: 16px;
  }

  .report-input-container {
    row-gap: 20px;
    padding: 16px !important;
  }

  .pagination {
    padding: 10px 0;
  }

  .reports-list {
    overflow-x: auto;
  }

  .reports-list .list-group {
    min-width: 1024px;
  }

  .file-action-title {
    text-align: center;
  }

}
