.checkout {
  margin-top: 10px;
  margin-bottom: 10px;
}

.checkout .table > tbody > tr > td,
.checkout .table > tbody > tr > th,
.checkout .table > tfoot > tr > td,
.checkout .table > tfoot > tr > th,
.checkout .table > thead > tr > td,
.checkout .table > thead > tr > th {
  vertical-align: middle;
}

.checkout .table > thead > tr > th {
  background: #2f6e89;
  color: #fff;
}

.panel-default > .panel-heading {
  background-color: #393542;
  border-color: #eee;
  color: #fff;
}

.checkout .img-responsive {
  width: 30px;
  height: 30px;
}

@media only screen and (max-width: 1024px) {
  .checkout-container {
    margin: 80px 10px 20px 5px;
  }

  .checkout {
    overflow-x: auto;
    white-space: nowrap;
  }
}
