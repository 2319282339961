.related-reports-root .h3-heading {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
}

.related-reports-container {
  margin-bottom: 24px;
}

.related-reports-container ul {
  border: 1px solid #dddddd;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: inline-block;
  width: 100%;
}

.related-reports-container li {
  border-bottom: 1px solid #dddddd;
  list-style-type: none;
  padding: 10px;
  display: inline-block;
  width: 100%;
}

.related-reports-container li a {
  color: #141414;
}

.related-reports-container li a:hover {
  color: #009cde;
}
