.features-root {
  margin: 40px 0;
  line-height: 22px;
}

.features-root h3 {
  margin-top: 48px;
  margin-bottom: 32px;
  font-size: 26px;
  font-weight: 700;
  color: #04525f;
  text-align: center;
}

.features-container {
  display: flex;
  margin: 15px auto;
  column-gap: 30px;
}

.feature-card-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #dfe1e6;
  background: #fff;
  flex: 1;
  box-shadow: 0px 8px 12px 0px rgba(9, 30, 66, 0.08),
    0px 2px 6px 0px rgba(9, 30, 66, 0.04);
}

.feature-card-icon {
  border-radius: 60px;
  background: #E7FCFF;
  padding: 8px;
}

.feature-card-title {
  font-size: 18px;
  margin: 0 0 0 0;
  font-weight: 600;
}

.feature-card-description {
  font-size: 14px;
  margin-top: 8px;
  text-align: center;
}

@media only screen and (max-width: 1024px) {

  .features-root {
    margin: 10px 5px;
    line-height: 18px;
  }

  .features-root h3 {
    font-size: 20px;
    margin-bottom: 8px;
  }

  .underline {
    width: 40px;
  }

  .features-container {
    flex-direction: column;
    align-items: center;
  }

  .feature-card-icon {
    border-radius: 30px;
  }

  .feature-card-root {
    margin-bottom: 15px;
    gap: 8px;
    padding: 16px;
  }

  .feature-card-heading {
    padding: 8px 10px;
    font-size: 14px;
  }

  .feature-card-title {
    font-size: 16px;
  }

  .feature-card-description {
    font-size: 14px;
    margin-top: 0;
  }
}
