.filter-root {
  position: sticky;
  top: 0;
  margin: 16px;
}

.report-filter-heading {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  border-radius: 8px 8px 0px 0px;
  background: #04525f;
  padding: 10px;
  color: #fff;
}

.report-filter-heading button {
  font-size: 14px;
  padding: 4px;
  border-radius: 4px;
  background: #fff;
}

.filter-root .h3-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  margin: 10px 0 0 0;
  font-weight: 600;
  color: #000;
}

.radio-container {
  margin-bottom: 20px;
  border: 1px solid #dddddd;
  margin: 0;
  padding: 0;
  width: 100%;
}

.filter-radio,
.filter-checkbox {
  border-bottom: 1px solid #dddddd;
  width: 100%;
  padding: 6px 0;
}

.checkbox-container {
  border: 1px solid #dddddd;
  margin: 0;
  padding: 0;
  width: 100%;
}

.show-all-btn {
  text-align: center;
  border-bottom: 1px solid #dddddd;
  width: 100%;
  padding: 10px 0px;
}

.show-all-btn button {
  background: #1498b1 none repeat scroll 0 0;
  border: 0 none;
  border-radius: 3px;
  color: #fff !important;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  padding: 3px 6px;
  width: auto;
}

.form-check-input {
  border-color: #000 !important;
  margin-left: 0 !important;
  margin-right: 10px;
}

.form-check-label {
  color: black;
  width: 75%;
  word-wrap: break-word;
}

.filter-reset-all-btn {
  background: #1498b1;
}
