.consulting-services-container {
  font-size: 16px;
}

.consulting-services-p {
  margin-top: 8px;
  letter-spacing: 0.2px;
}

.consulting-services-p1 {
  margin-top: 8px;
  font-size: 18px;
  font-weight: 800px;
}

@media only screen and (max-width: 1028px) {
  .consulting-services-container {
    margin-left: 24px !important;
    margin-right: 24px !important;
  }

  .consulting-services-p1 {
    font-size: 14px;
  }
}
