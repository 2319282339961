.privacy-policy-h {
  text-align: center;
  font-weight: bold;
  font-size: 40px;
  margin-bottom: 5px;
}

.privacy-policy-container {
  margin-left: 120px;
  margin-right: 100px;
  margin-bottom: 80px;
}

.privacy-policy-p {
  font-size: 18px;
  margin-left: 2px;
}

.privacy-policy-li {
  font-size: 18px;
}

.privacy-policy-h4 {
  font-weight: 600;
  margin-top: 18px;
  margin-left: 2px;
}

.privacy-policy-strong-title {
  font-weight: 600;
  font-size: 18px;
}

@media only screen and (max-width: 1024px) {
  .privacy-policy-container {
    margin: 10px 15px 50px 15px;
  }

  .privacy-policy-h4 {
    font-size: 18px;
  }

  .privacy-policy-p {
    font-size: 14px;
  }

  .privacy-policy-strong-title {
    font-weight: 500;
    font-size: 16px;
  }

  .privacy-policy-li {
    font-size: 14px;
  }
}
