.become-publisher-container {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}

.become-publisher-container .form-actions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 8px;
}

.become-publisher-form-input {
  border-color: black !important;
}

.become-publisher-form-root {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.become-publisher-form {
  width: 480px;
}

.become-publisher-container .form-group {
  font-weight: bold;
  padding: 4px 0;
  font-size: 20px !important;
}

.become-publisher-container .submit-btn {
  margin-top: 16px;
}

@media only screen and (max-width: 1024px) {
  .become-publisher-form {
    width: 100%;
  }

  .become-publisher-container .form-group {
    padding: 4px;
  }
}
