#main-nav-content {
  background: var(--secondary);
  padding-top: 24px;
  margin-top: -24px;
}

#main-nav-content .center {
  display: flex;
  column-gap: 8px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  z-index: 50;
  width: 100%;
  padding: 14px 0 8px;
  max-width: 1340px;
  margin: 0 auto;
}

.main-nav-lvl-0 {
  flex: 0 0 auto;
  padding-right: 8px;
  text-transform: capitalize;
  text-align: center;
  margin: 4px 0;
}

.main-nav-lvl-0 > a {
  color: #f0f3f7;
  font-size: 13.5px;
  text-align: center;
  line-height: 13px;
  display: inline-block;
  letter-spacing: 0.2px;
  position: relative;
  font-weight: 500;
}

.main-nav-lvl-0 > a:hover {
  color: #d2d4d7;
}

.main-nav-lvl-0 > a:before {
  content: "";
  display: block;
  top: 50%;
  left: -8px;
  position: absolute;
  z-index: 2;
  width: 1px;
  opacity: 1;
  height: 24px;
  margin-top: -12px;
  background: #7599b4;
}

.main-nav-lvl-0.no-before-line > a:before {
  display: none;
}

@media only screen and (max-width: 1024px) {
  .main-nav-lvl-0 > a:before {
    display: none;
  }

  .main-nav-lvl-0 {
    flex: 1 0 30%;
  }
}
