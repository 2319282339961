.popular-categories-root {
  box-sizing: border-box;
  border: 1px solid #dedede;
  border-radius: 16px;
  box-shadow: 0px 8px 12px 0px rgba(9, 30, 66, 0.08);
}

.popular-categories-root-title {
  border-radius: 16px 16px 0px 0px;
  background: var(--primary);
}

.popular-categories-root h4 {
  font-size: 18px;
  background: var(--secondary);
  color: #fff;
  padding: 15px 5px;
  margin: 0;
  text-align: center;
}

.popular-categories-root ul {
  margin: 0;
  padding: 0;
  height: 420px;
  overflow-y: auto;
}

.category:hover {
  background-color: #f4f5f7;
}

.category a {
  display: inline-block;
  padding: 8px 32px;
  width: 100%;
  color: #000;
  margin: 0;
  text-align: left;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.category a img {
  max-width: 100%;
}

.category-image {
  height: 20px;
  width: 20px;
}

@media only screen and (max-width: 1024px) {
  .popular-categories-root {
    font-size: 12px;
  }

  .popular-categories-root h4 {
    font-size: 16px;
    padding: 8px 0px;
  }

  .category a {
    font-size: 12px;
    padding: 6px 20px;
  }

  .category-image {
    height: 16px;
    width: 16px;
  }

  .popular-categories-root ul {
    height: 300px;
  }
}
