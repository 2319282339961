.reports-list-container {
  display: flex;
  flex-wrap: wrap;
  gap: 48px 24px;
}

.reports-list-card-root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 290px;
  border: 1px solid #dfe1e6;
  background: #fff;
  box-shadow: 0px 8px 12px 0px rgba(9, 30, 66, 0.08),
    0px 2px 6px 0px rgba(9, 30, 66, 0.04);
  border-radius: 8px;
}

.reports-list-card-top-root {
  color: #000;
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  font-size: 14px;
}

.reports-list-card-top-root:hover {
  color: #000;
}

.reports-list-card-caregory-chip {
  display: flex;
  gap: 8px;
  align-items: center;
  background-color: #e0fbff;
  padding: 4px 8px;
  border-radius: 8px;
  font-size: 12px;
  color: #04525f;
}

.reports-list-card-title {
  text-decoration: none !important;

  font-weight: 600;
}

.reports-list-card-descr {
  color: #757575;
}

.reports-list-card-free-sample-btn {
  display: flex;
  padding: 12px 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  color: #04525f;
  border-radius: 0px 0px 0px 8px;
  border: none;
  font-weight: 600;
  background: #f0f0f0;
}

.reports-list-card-free-sample-btn:hover {
  color: #04525f;
}

.reports-list-card-buy-now-btn {
  display: flex;
  padding: 12px 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  border-radius: 0px 0px 8px 0px;
  background: #04525f;
  color: #fff;
  border: none;
}

.reports-list-card-buy-now-btn:hover {
  color: #fff;
}

@media only screen and (max-width: 1024px) {
  .reports-list-container {
    justify-content: center;
    gap: 24px;
  }
}
