@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --primary: #1d545c;
  --secondary: #055160;
  --reportsHeading: #1e9082;
  --lettersColor: #198da4;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif !important;
  line-height: 1.42857143;
  font-size: 13px !important;
}

pre {
  font-family: "Poppins", sans-serif !important;
  line-height: 1.42857143;
  font-size: 16px !important;
  white-space: pre-wrap;
  word-wrap: break-word;
  margin-top: 8px !important;
  padding: 20px;
}

a {
  text-decoration: none !important;
}

p {
  margin-bottom: 0 !important;
}

.row {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

h3 {
  font-size: 18px;
  font-weight: 600;
}

.panel {
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}

.accordion-item-container {
  flex: 1;
  display: flex;
  align-items: center;
}

.accordion-item-container span {
  flex: 8;
}

.accordion-item-container Button {
  flex: 1;
  margin-right: 20px;
}

.accordion-item-title {
  font-size: 16px;
  margin: 0px 20px;
}

.margin-horizontal-homepage {
  margin: 0 120px !important;
}

.margin-horizontal-report-detail-page {
  margin: 0 120px !important;
}

.margin-horizontal-checkout-page {
  margin: 0 120px !important;
}

.margin-horizontal-faqs-page {
  margin: 0 120px !important;
}

.margin-horizontal-tnc-page {
  margin: 0 120px !important;
}

.margin-horizontal-order-process {
  margin: 0 120px !important;
}

.margin-horizontal-about-us {
  margin: 0 120px !important;
}

.margin-horizontal-consulting-services-page {
  margin: 0 120px 100px 120px !important;
}

.horizontal-margin-syndicated-research-root {
  margin: 0 120px 100px 120px !important;
}

.horizontal-margin-customized-research-root {
  margin: 0 120px 100px 120px !important;
}

@media only screen and (max-width: 1024px) {
  pre {
    font-size: 14px !important;
  }

  .margin-horizontal-homepage {
    margin: 0 16px !important;
  }

  .margin-horizontal-report-detail-page {
    margin: 0 !important;
  }

  .margin-horizontal-checkout-page {
    margin: 0 16px !important;
  }

  .margin-horizontal-faqs-page {
    margin: 0 16px !important;
  }

  .margin-horizontal-tnc-page {
    margin: 0 16px !important;
  }

  .margin-horizontal-order-process {
    margin: 0 16px !important;
  }

  .become-publisher-container {
    margin: 16px !important;
  }

  .margin-horizontal-about-us {
    margin: 0 16px !important;
  }

  .margin-horizontal-consulting-services-page {
    margin: 0 16px !important;
  }
}
