.order-process-root {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.order-process-title {
  font-weight: 700;
  font-size: 24px;
}

.order-process-p-container {
  margin-bottom: 24px;
}

.order-process-p {
  font-size: 16px;
  margin: 8px 0 0;
}

.order-process-root .notes-text {
  font-size: 16px;
  font-weight: 600;
}

.order-process-root .notes-list li {
  font-size: 14px;
  margin-top: 4px;
}

.order-process-root .steps-list li {
  font-size: 16px;
  margin-top: 4px;
}

.order-process-heading {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 12px;
}

.order-process-step-heading {
  font-size: 16px;
  font-weight: 600;
}

@media only screen and (max-width: 1024px) {
  .order-process-root {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  
  .order-process-title {
    font-weight: 700;
    font-size: 18px;
  }
  
  .order-process-p-container {
    margin-bottom: 16px;
  }
  
  .order-process-p {
    font-size: 14px;
    margin: 8px 0 0;
  }
  
  .order-process-root .notes-text {
    font-size: 14px;
    font-weight: 600;
  }
  
  .order-process-root .notes-list li {
    font-size: 13px;
    margin-top: 4px;
  }
  
  .order-process-root .steps-list li {
    font-size: 14px;
    margin-top: 4px;
  }
  
  .order-process-heading {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 12px;
  }
  
  .order-process-step-heading {
    font-size: 14px;
    font-weight: 600;
  }
}