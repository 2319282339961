.breadcrumb {
  overflow: hidden !important;
  padding: 4px 10px !important;
  margin: 8px 28px 16px 28px !important;
  border-bottom: 1px solid black;
  width: max-content;
}

.breadcrumb-item a {
  color: #000 !important;
}

.breadcrumb-item a:hover {
  text-decoration: underline !important;
}

.breadcrumb-item::before {
  color: #000 !important;
}

.breadcrumb .active {
  color: #000 !important;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: ">" !important;
}