.admin-dashboard-messages-root span {
  border-bottom: 5px solid #463f39;
  display: block;
  margin-top: 7px;
  width: 35%;
}

.admin-dashboard-messages-root h2 {
  width: max-content;
  font-weight: 600;
}

.messages-segmentation-buttons {
  margin-top: 32px;
  display: flex;
  justify-content: center;
  column-gap: 8px;
}

.message-root {
  padding: 20px;
  margin: 16px;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  display: flex !important;
  flex-direction: row !important;
  column-gap: 32px;
  font-size: 16px;
}

.message-root h4 {
  text-decoration: underline;
  margin-bottom: 16px;
  font-weight: 600;
}

.message-root table td {
  padding-right: 16px;
}

.message-box-1 {
  flex: 2;
}

.message-box-2 {
  flex: 4;
}

.message-box-3 {
  flex: 1;
}

.message-box-3-button {
  padding: 0 !important;
}

.message-box-3-icon {
  font-size: 36px;
  color: #000000;
}

@media only screen and (max-width: 1024px) {
  .messages-segmentation-buttons {
    flex-direction: column;
    align-items: center;
    row-gap: 8px;
  }

  .message-box-1, .message-box-2, .message-box-3 {
    flex: unset;
    overflow-x: auto;
  }

  .message-box-1 {
    width: 100%;
  }

  .message-root {
    flex-direction: column !important;
    row-gap: 16px;
  }

  .message-box-3-icon {
    font-size: 24px;
  }

  .message-box-3 > div {
    justify-content: center;
  }
}
