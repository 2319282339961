.popular-reports-root {
  line-height: 22px;
  margin: 20px 0;
}

.popular-reports-root h3 {
  margin-top: 48px;
  margin-bottom: 32px;
  font-size: 26px;
  font-weight: 700;
  color: #04525f;
  text-align: center;
}

.popular-reports-container {
  margin: 15px auto;
  padding: 0;
}

.popular-reports-card-container {
  display: flex;
  column-gap: 24px;
}

.popular-reports-card-root {
  height: 100%;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid var(--fill-fill-primary-disabled, #dfe1e6);
  background: #fff;
  box-shadow: 0px 8px 12px 0px rgba(9, 30, 66, 0.08),
    0px 2px 6px 0px rgba(9, 30, 66, 0.04);
}

.popular-reports-root .report-link {
  font-size: 16px;
  margin-bottom: 10px;
  display: inline-block;
  color: #15314a;
  font-weight: 600;
}

.popular-reports-root .report-description {
  color: #333;
  font-size: 15px;
  text-align: justify;
  font-weight: 400;
}

@media only screen and (max-width: 1024px) {
  .popular-reports-root {
    margin: 10px 0px;
    font-size: 15px;
  }

  .popular-reports-root h3 {
    font-size: 20px;
    margin-bottom: 8px;
  }

  .popular-reports-root .report-link {
    font-size: 14px;
  }

  .popular-reports-root .report-description {
    font-size: 12px;
  }
}
