.about-us-container {
  margin-top: 40px !important;
  margin-bottom: 80px !important;
}

.abt-us-title {
  font-weight: 700;
  font-size: 24px;
}

.abt-us-page-header {
  font-size: 16px;
}

.about-us-title {
  font-size: 18px;
  margin: 0 0 20px;
}

.title-abt-us {
  margin: 10px 0;
  font-size: 20px;
  font-weight: 600;
}

.list-about-us {
  font-size: 18px;
  margin: 0 0 20px;
}

.p-about-us {
  font-size: 18px;
  margin: 8px 0;
}

.abt-us-head {
  font-size: 18px;
  margin: 20px 0 10px;
  font-weight: 600;
}

@media only screen and (max-width: 1024px) {

  .about-us-container {
    margin-top: 20px !important;
    margin-bottom: 40px !important;
  }

  .abt-us-title {
    font-size: 18px;
  }

  .about-us-title {
    font-size: 16px;
    margin: 0 0 14px;
  }

  .title-abt-us {
    font-size: 18px;
    margin: 4px 0;
  }

  .p-about-us {
    font-size: 16px;
    margin: 4px 0;
  }

  .abt-us-head {
    font-size: 16px;
  }

  .list-about-us {
    font-size: 16px;
    margin: 0 0 14px;
  }
}
