.pagination-root {
  margin: 28px 0px;
  display: flex;
  justify-content: center;
}

.custom-pagination .page-link {
  color: black;
  border: 1px solid black;
}

.custom-pagination .page-link:hover {
  color: black;
  border: 1px solid black;
}

.custom-pagination .page-item.active .page-link {
  color: white;
  border-color: black;
  background-color: var(--secondary);
}

@media only screen and (max-width: 1024px) {
  .custom-pagination .page-link {
    font-size: 1rem;
    padding: 0.3rem 0.5rem;
  }

  .custom-pagination .page-item.active .page-link {
    font-size: 1rem;
    padding: 0.3rem 0.5rem;
  }
}
