.subfooter-container {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 128px;
  background-color: black;
  padding: 16px;
}

.pay-images {
  display: flex;
  column-gap: 8px;
}

.pay-images img {
  height: 30px;
}

.copyright-text {
  color: white;
  text-align: center;
  width: 100%;
}

.socials-icons {
  display: flex;
  column-gap: 16px;
  justify-content: center;
  width: 100%;
}

@media only screen and (max-width: 1024px) {
  .subfooter-container {
    flex-direction: column;
    row-gap: 32px;
    align-items: center;
  }
}
