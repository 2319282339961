.main-report-detail-root {
  padding: 24px 16px 16px 16px;
  margin-bottom: 8px;
}

.main-report-detail-header-root {
  margin: 0px 0 32px 0;
}

.report-detail-text {
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 600;
  color: var(--secondary);
  border-bottom: 2px solid var(--primary);
  width: max-content;
  padding: 0 8px 0 0;
}

.blink-me {
  animation: blink 3s linear infinite;
  float: right;
}

@keyframes blink {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.title-link-report {
  text-decoration: none !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  line-height: 24px;
  color: #141414 !important;
}

.custom-header-panel {
  background-color: #0075ff !important;
  border-color: #0075ff !important;
  color: #000;
  border-radius: 6px;
  padding: 8px 48px;
  text-decoration: none;
  border: none;
  background: none;
  width: inherit;
  font-size: 14px;
}

.report-detail-info {
  color: #607290;
  font-size: 14px;
}

.tablist > li.active > button,
.tablist > li.active > button:focus,
.tablist > li.active > button:hover {
  border-bottom: 3px solid var(--primary) !important;
  font-weight: bold;
  background: white;
}

.tablist li button {
  font-size: 15px !important;
  border-bottom: 3px solid #dfe1e6 !important;
}

.tablist > li > button {
  background: none;
  border: none;
  color: var(--primary);
}

.nav > li > button {
  padding: 12px;
  font-weight: 500;
  font-size: 14px;
}

.main-header-left {
  padding-left: 0;
}

.main-header-right {
  padding-right: 0;
}

.main-header-title {
  text-transform: capitalize;
  font-size: 17px;
}

.report-title-wrapper {
  display: flex;
  justify-content: space-between;
  column-gap: 16px;
}

.download-btn-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-width: 140px;
}

.download-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: max-content;
  padding: 8px 16px;
  column-gap: 8px;
  color: #02a971;
  background-color: #e6f6f1;
  border: 1px solid #02a971;
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  overflow: hidden;
  width: max-content;
  transition: all 0.3s;
}

.download-btn-txt {
  max-width: 0;
  display: block;
  margin-left: -8px;
  max-width: 140px;
  margin-left: 0;
}

.download-btn:hover .download-btn-txt {
  max-width: 140px;
  margin-left: 0;
}

@media only screen and (max-width: 1024px) {
  .custom-header-panel {
    padding: 8px 24px;
    margin: 8px auto;
  }

  .main-report-detail-top {
    margin-top: 16px !important;
  }

  .title-link-report {
    font-size: 16px !important;
    line-height: 20px;
  }

  .report-detail-info {
    font-size: 12px;
  }

  .main-report-detail-root .d-flex.gap-4 {
    gap: 12px !important;
    margin-top: 24px !important;
  }

  .tablist {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .nav > li > button {
    padding: 4px;
    font-size: 12px;
  }

  .download-btn-container {
    min-width: auto;
  }

  .download-btn:hover .download-btn-txt {
    max-width: 0;
    margin-left: -8px;
  }
}
