.custom-market-research-root {
  padding: 24px;
  border-radius: 24px;
  border: 1px solid #04525f;
  background: linear-gradient(0deg, #d4f9ff 0%, #d4f9ff 100%), #fff;
  box-shadow: 0px 8px 12px 0px rgba(9, 30, 66, 0.08),
    0px 2px 6px 0px rgba(9, 30, 66, 0.04);
}

.custom-market-research-root p,
.custom-market-research-root li {
  color: #000;
  font-size: 14px;
  font-weight: 500;
}

.offer-heading {
  color: #04525f;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
}

@media only screen and (max-width: 1024px) {
  .custom-market-research-root {
    margin: 10px 0 0 0;
    padding: 10px;
  }

  .custom-market-research-root p, .custom-market-research-root li {
    font-size: 12px;
  }

  .custom-market-research-root .row {
    margin-right: -10px !important;
    margin-left: -10px !important;
  }

  .offer-heading {
    padding: 15px;
    font-size: 16px;
  }
}
