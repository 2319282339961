.latest-reports-root {
  border: 1px solid #dedede;
  font-size: 13px;
  overflow-y: auto;
  border-radius: 16px 16px 16px 16px;
  box-shadow: 0px 8px 12px 0px rgba(9, 30, 66, 0.08);
}

.latest-reports-root h4 {
  font-size: 18px;
  background: rgb(5, 81, 96);
  color: #fff;
  padding: 15px 5px;
  margin: 0;
  text-align: center;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 16px 16px 0px 0px;
  background: #04525f;
}

.latest-reports-root .report-title {
  color: #141414;
  font-weight: 660;
  text-align: left;
  line-height: 17px;
}

.reports-container {
  overflow: hidden;
  max-height: 420px;
  border-radius: 16px 16px 0px 0px;
}

.reports-scroll {
  display: flex;
  flex-direction: column;
  animation: scroll 50s linear infinite;
  animation-play-state: running;
  position: relative;
  --totalHeight: 0px;
}

.reports-scroll.paused {
  animation-play-state: paused;
}

.report-item {
  display: flex;
  flex-direction: row;
  padding: 10px 20px;
}

.report-item:hover {
  background-color: #f4f5f7;
}

.report-item .publisher-text,
.report-item .published-date-text {
  color: #000;
}

.running-img.img-thumbnail {
  width: 30px;
  height: 30px;
  margin-bottom: 10px;
  margin-right: 10px;
}

.report-publisher-date {
  display: flex;
  gap: 4px;
  margin-top: 4px;
}

.publisher-date {
  color: var(--lettersColor);
}

@keyframes scroll {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(calc(-0.5 * var(--totalHeight)));
  }
}

@media only screen and (max-width: 1024px) {
  .latest-reports-root {
    margin-top: 20px;
    font-size: 12px;
  }

  .latest-reports-root h4 {
    font-size: 16px;
    padding: 8px 0px;
  }

  .reports-container {
    padding: 10px;
    max-height: 300px;
  }

  .report-item {
    flex-direction: column;
    align-items: center;
  }

  .running_img.img-thumbnail {
    display: none;
  }
}
