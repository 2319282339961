.page-header-title {
    text-align: center;
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 16px
}

@media only screen and (max-width: 1024px) {
    .page-header-title {
        margin-top: 24px;
        font-size: 28px;
    }
}