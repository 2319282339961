.publisher-cards-container {
  width: 50%;
}

.publisher-card-container {
  border: 1px solid #eee;
  padding: 10px;
  margin: 8px auto;
  border-radius: 10px;
  align-items: center;
  justify-content: space-between;
  box-shadow: 2px 2px 2px #eee;
}

.publisher-card-image {
  width: 50px;
  height: 50px;
  border-radius: 10%;
  margin-right: 10px;
  border: 1px solid #333;
  object-fit: cover;
  padding: 5;
}

.publisher-card-title {
  flex-grow: 1;
  font-size: 1.2em;
  font-weight: bold;
  color: #333;
}

@media only screen and (max-width: 1024px) {
  .publisher-cards-container {
    width: 80%;
  }
}
