.contact-us-form-handle {
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 0;
  box-shadow: none;
  color: #141414;
  display: block;
  font-size: 14px;
  line-height: 1.42857;
  padding: 8px 3px;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  width: 100%;
}

.contact-us-leave-a-message h4 {
  font-size: 18px;
  background: var(--primary);
  color: white;
  padding: 15px 5px;
  margin: 0;
  text-align: center;
}

.contact-us-leave-a-msg-form-grp {
  margin-bottom: 15px;
}

.row-sub-btn {
  margin-top: 10px;
  text-align: center;
  display: flex;
  justify-content: center;
}

.contact-us-form-label {
  font-weight: bold;
}

.contact-us-address-li {
  font-size: 16px;
}

.list-unstyled {
  margin-left: 16px;
}

.contact-address {
  margin-left: 10px !important;
}

.contact-person-container {
  margin-left: 1px !important;
}
