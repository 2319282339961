.admin-dashboard-root {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.admin-dashboard-header {
  padding: 12px 48px !important;
  min-height: 80px;
  max-height: 80px;
}

.columns-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: calc(100vh - 80px);
}

.columns-container .flex-column {
  width: 15%;
  height: 100%;
  background-color: #f8f9fa;
  border-right: 1px solid #dee2e6;
  padding: 32px 0px 64px 0px;
  align-items: center;
  overflow: hidden;
}

.nav-links-container {
  width: 100%;
}

.columns-container .flex-column .nav-link {
  font-size: 20px;
  color: #495057;
  width: 100%;
  padding: 12px 32px;
}

.columns-container .flex-column .nav-link.active {
  font-weight: 600;
  background-color: #e2e2e2;
  width: 100%;
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-text {
  color: #fff;
  margin-right: 10px;
}

.component-container {
  margin: 32px 0 0 32px;
  padding-right: 32px;
  width: calc(100vw - 15%);
  overflow-x: hidden;
  overflow-y: auto;
}

.login-status {
  font-size: 16px;
}

@media only screen and (max-width: 1024px) {
  .admin-dashboard-root {
    overflow-y: auto;
  }

  .columns-container {
    flex-direction: column;
    height: auto;
  }

  .nav-links-container {
    margin-top: 20px;
  }

  .nav-link {
    text-align: center;
  }

  .columns-container .flex-column {
    width: 100%;
    height: auto;
    border-right: none;
    border-bottom: 1px solid #dee2e6;
    padding: 20px 0;
    flex-direction: column-reverse !important;
  }

  .columns-container .flex-column .nav-link {
    font-size: 16px;
    padding: 12px 16px;
  }

  .component-container {
    width: 100%;
    height: auto;
    margin: 16px 0 0 16px;
    padding-right: 16px;
  }

  .admin-dashboard-header {
    padding: 12px 16px !important;
  }

  .login-status {
    font-size: 14px;
  }
}

@media only screen and (max-width: 1024px) {
  .admin-dashboard-root {
    overflow-y: auto;
  }

  .columns-container {
    flex-direction: column;
    height: auto;
  }

  .nav-links-container {
    margin-top: 20px;
  }

  .nav-link {
    text-align: center;
  }

  .columns-container .flex-column {
    width: 100%;
    height: auto;
    border-right: none;
    border-bottom: 1px solid #dee2e6;
    padding: 20px 0;
    flex-direction: column-reverse !important;
  }

  .columns-container .flex-column .nav-link {
    font-size: 16px;
    padding: 12px 16px;
  }

  .component-container {
    width: 100%;
    height: auto;
    margin: 16px 0 0 16px;
    padding-right: 16px;
  }

  .admin-dashboard-header {
    padding: 12px 16px !important;
  }

  .login-status {
    font-size: 14px;
  }
}
