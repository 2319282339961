.purchase-report-root {
  background: #f8f8f8;
  padding: 20px 10px;
  margin-bottom: 20px;
}

.purchase-report-root .top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 24px 12px;
}

.purchase-report-root .top-bar .form-select {
  width: max-content;
  font-size: 14px !important;
  color: var(--primary);
  font-weight: 600;
  border-color: var(--primary);
}

.purchase-report-root .h3-heading {
  font-size: 20px;
  font-weight: bold;
}

.purchase-report-root .tab-content {
  background: #fff;
}

.tab-pane {
  border: hidden;
  padding: 20px;
  border-top: 0;
}

.tab-content table {
  margin-bottom: 0;
}

.purchase-btn-div {
  display: flex;
  justify-content: center;
}

.purchase-report-root .table > tbody > tr > td,
.purchase-report-root .table > tbody > tr > th,
.purchase-report-root .table > tfoot > tr > td,
.purchase-report-root .table > tfoot > tr > th,
.purchase-report-root .table > thead > tr > td,
.purchase-report-root .table > thead > tr > th {
  vertical-align: middle;
}

.purchase-report-root .radio {
  margin: 0;
}

label {
  font-size: 14px;
}

.purchase-buttons {
  display: flex;
  align-items: center !important;
  justify-content: center;
  column-gap: 8px;
  background: #02a971;
  color: #fff !important;
  border: 0;
  width: max-content;
  text-align: center;
  font-size: 14px;
  border-radius: 4px;
  padding: 8px 16px;
  margin-top: 14px;
}

.checkout-label {
  margin-right: 10px;
}

.checkout-label-tr {
  border: hidden;
}
