.homepage-search-root {
  position: relative;
  z-index: 200;
  border-radius: 4px 4px 0 0;
  margin-top: 16px;
}

.homepage-search-bar {
  background-color: #246a9f;
}

.homepage-search-root .center {
  width: 100%;
  max-width: 1340px;
  margin: 0 auto;
  padding: 0 10px;
  background: var(--secondary);
}

#search-bar-container {
  padding-top: 10px;
}

#search-bar-container form {
  width: 100%;
  max-width: none;
  float: left;
  position: relative;
}

#search-bar-container input.search-box {
  padding: 8px 70px 8px 20px;
  border-radius: 3px 10px 10px 3px;
  font-size: 16px;
  display: block;
  margin-right: 70px;
  width: 100% !important;
  text-align: left;
  color: #000;
  font-weight: 400;
}

#search-bar-container button {
  padding: 4px 20px 0;
  border-radius: 0 4px 4px 0;
  background: #0a2942;
  width: 70px;
  position: absolute;
  top: 0;
  text-align: center;
  right: 0;
  height: 44px;
}

@media only screen and (max-width: 1024px) {
  #search-bar-container input.search-box {
    padding: 8px 35px 8px 10px;
    font-size: 14px;
    margin-right: 35px;
  }

  #search-bar-container button {
    width: 48px;
    padding: 2px 10px 0;
    height: 41px;
  }

  .center {
    padding: 0 5px;
  }

  .homepage-search-root {
    margin-top: 8px;
  }

  #search-bar-container {
    padding-top: 5px;
    background-color: var(--secondary);
    border: var(--secondary);
    border-bottom: 20px var(--secondary);
  }
}
