.customized-research-p {
  font-size: 18px;
  letter-spacing: 0.2px;
  margin-top: 8px;
}

.customized-research-h {
  margin-top: 20px;
  font-weight: 600;
}

.customized-research-li {
  font-size: 18px;
  letter-spacing: 0.2px;
  margin-top: 8px;
}
.p-and-li {
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 1028px) {
  .customized-research-root {
    margin-left: 24px !important;
    margin-right: 24px !important;
  }

  .customized-research-li {
    font-size: 14px;
  }

  .customized-research-p {
    font-size: 14px;
  }
}
