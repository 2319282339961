.syndicated-research-p {
  font-size: 18px;
  letter-spacing: 0.2px;
  margin-top: 8px;
}

@media only screen and (max-width: 1028px) {
  .syndicated-research-root {
    margin-left: 24px !important;
    margin-right: 24px !important;
  }
}
